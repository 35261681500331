import { IconType } from "Icons/types";
import { uniqueId } from "lodash";

import { SerializedStyles, css } from "@emotion/react";

import { getAvatarPath } from "utils/file";

import { white } from "constants/colors";
import { placeholderAvatar, placeholderOrgLogo } from "constants/images";

const sizeProps: Record<FrameSize, { strokeWidth: number; imageSize: number }> =
  {
    114: {
      strokeWidth: 2,
      imageSize: 102,
    },
    80: {
      strokeWidth: 2,
      imageSize: 72,
    },
    72: {
      strokeWidth: 1.8,
      imageSize: 64.8,
    },
    64: {
      strokeWidth: 1.6,
      imageSize: 57.6,
    },
    56: {
      strokeWidth: 1.4,
      imageSize: 48,
    },
    48: {
      strokeWidth: 1.2,
      imageSize: 43.2,
    },
    44: {
      strokeWidth: 1.1,
      imageSize: 39.6,
    },
    40: {
      strokeWidth: 1,
      imageSize: 36,
    },
    32: {
      strokeWidth: 0.8,
      imageSize: 28.8,
    },
    24: {
      strokeWidth: 0.6,
      imageSize: 21.6,
    },
    20: {
      strokeWidth: 0.4,
      imageSize: 20,
    },
    10: {
      strokeWidth: 0.2,
      imageSize: 10,
    },
  };

export const frameShapePaths: Record<FrameShape, string> = {
  curvedSquare:
    "M30.2237 44.164C26.3727 42.0876 21.6279 42.0871 17.7768 44.164C11.3009 47.6564 6.27663 46.844 3.71632 44.2836C1.15602 41.7232 0.343683 36.6989 3.83627 30.2231C5.91311 26.3723 5.9131 21.6277 3.83626 17.7769C0.343683 11.3011 1.15602 6.27681 3.71633 3.71646C6.27664 1.1561 11.301 0.34366 17.7768 3.83603C21.6279 5.91288 26.3728 5.91257 30.2237 3.83567C36.698 0.343911 41.722 1.15605 44.2828 3.71619C46.8434 6.27626 47.6565 11.2998 44.1655 17.7747C42.0885 21.6269 42.0898 26.3729 44.1669 30.2245C47.6563 36.6947 46.8433 41.7181 44.2821 44.2793C41.7209 46.8405 36.697 47.6543 30.2237 44.164Z",

  diamond:
    "M22.5523 2.32013C23.2077 1.22662 24.7923 1.22662 25.4478 2.32013C30.4258 10.6251 37.375 17.5743 45.6799 22.5523C46.7734 23.2077 46.7734 24.7923 45.6799 25.4478C37.375 30.4258 30.4258 37.375 25.4478 45.6799C24.7923 46.7734 23.2077 46.7734 22.5523 45.6799C17.5743 37.375 10.6251 30.4258 2.32013 25.4478C1.22662 24.7923 1.22662 23.2077 2.32013 22.5523C10.6251 17.5743 17.5743 10.6251 22.5523 2.32013Z",

  hexagon:
    "M33.3603 1.5C34.0764 1.5 34.7632 1.78446 35.2695 2.29081L45.7092 12.7305C46.2156 13.2369 46.5001 13.9236 46.5001 14.6397V33.3603C46.5001 34.0764 46.2156 34.7632 45.7092 35.2695L35.2695 45.7092C34.7632 46.2156 34.0764 46.5001 33.3603 46.5001H14.6397C13.9236 46.5001 13.2369 46.2156 12.7305 45.7092L2.29081 35.2695C1.78446 34.7632 1.5 34.0764 1.5 33.3603V14.6397C1.5 13.9236 1.78446 13.2369 2.29081 12.7305L12.7305 2.29081C13.2369 1.78446 13.9236 1.5 14.6397 1.5H33.3603Z",
  circle:
    "M46.5001 24C46.5001 36.4264 36.4264 46.5001 24 46.5001C11.5736 46.5001 1.5 36.4264 1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5001 11.5736 46.5001 24Z",
  cloud:
    "M7,7a9.62,9.62,0,0,0-2.61,8.86,9.65,9.65,0,0,0,0,16.22A9.65,9.65,0,0,0,15.89,43.58a9.65,9.65,0,0,0,16.22,0A9.65,9.65,0,0,0,43.58,32.11a9.65,9.65,0,0,0,0-16.22A9.65,9.65,0,0,0,32.11,4.42a9.65,9.65,0,0,0-16.22,0A9.62,9.62,0,0,0,7,7Z",
  multiStar:
    "M18.6328 43.5585L17.8472 44.8363L18.6328 43.5585C17.921 43.1208 17.1084 42.8691 16.2733 42.823L11.1739 42.5412C10.3845 42.4976 9.73858 42.0208 9.47988 41.3854L7.62427 36.8275L6.23499 37.3931L7.62427 36.8275C7.30926 36.0537 6.79441 35.3786 6.13657 34.8629L2.18404 31.7642C1.60564 31.3107 1.38298 30.6059 1.55919 29.9625L2.85516 25.2304C3.07596 24.4242 3.07596 23.5759 2.85516 22.7696L1.55919 18.0376C1.38298 17.3942 1.60564 16.6894 2.18404 16.2359L6.13657 13.1372C6.79441 12.6214 7.30926 11.9463 7.62427 11.1726L9.47988 6.61469C9.73858 5.97924 10.3845 5.50247 11.1739 5.45885L16.2733 5.17705C17.1084 5.13091 17.921 4.87923 18.6328 4.44159L17.8586 3.18229L18.6328 4.44159L22.9312 1.79882C23.5793 1.40039 24.4208 1.40039 25.0688 1.79883L29.3672 4.44159C30.0791 4.87923 30.8917 5.13091 31.7268 5.17705L36.8262 5.45885C37.6155 5.50247 38.2615 5.97924 38.5202 6.61469L40.3758 11.1726C40.6908 11.9463 41.2056 12.6214 41.8635 13.1372L45.816 16.2359C46.3944 16.6894 46.6171 17.3942 46.4409 18.0376L45.1449 22.7696C44.9241 23.5759 44.9241 24.4242 45.1449 25.2304L46.4409 29.9625C46.6171 30.6059 46.3944 31.3107 45.816 31.7642L41.8635 34.8629C41.2056 35.3786 40.6908 36.0537 40.3758 36.8275L38.5202 41.3854C38.2615 42.0208 37.6155 42.4976 36.8262 42.5412L31.7268 42.823C30.8917 42.8691 30.0791 43.1208 29.3672 43.5585L25.0688 46.2012C24.4208 46.5997 23.5793 46.5997 22.9312 46.2012L18.6328 43.5585Z",
  rotatedHexa:
    "M21.7879 2.11556C23.1847 1.43739 24.8154 1.43739 26.2122 2.11556L37.9489 7.81396C38.9711 8.31026 39.7967 9.13588 40.293 10.1581L45.9914 21.8948C46.6696 23.2916 46.6696 24.9223 45.9914 26.3191L40.293 38.0558C39.7967 39.078 38.9711 39.9037 37.9489 40.4L26.2122 46.0984C24.8154 46.7765 23.1847 46.7765 21.7879 46.0984L10.0511 40.4C9.02894 39.9037 8.20333 39.078 7.70703 38.0558L2.00863 26.3191C1.33046 24.9223 1.33046 23.2916 2.00863 21.8948L7.70703 10.1581C8.20333 9.13588 9.02894 8.31026 10.0511 7.81396L21.7879 2.11556Z",
  roundedSquare:
    "M1.5 8.47059C1.5 4.62084 4.62084 1.5 8.47059 1.5H39.5294C43.3792 1.5 46.5 4.62084 46.5 8.47059V39.5294C46.5 43.3792 43.3792 46.5 39.5294 46.5H8.47059C4.62084 46.5 1.5 43.3792 1.5 39.5294V8.47059Z",
};

export type FrameShape =
  | "curvedSquare"
  | "diamond"
  | "hexagon"
  | "circle"
  | "cloud"
  | "multiStar"
  | "rotatedHexa"
  | "roundedSquare";

export type FrameSize =
  | 114
  | 80
  | 72
  | 64
  | 56
  | 48
  | 44
  | 40
  | 32
  | 24
  | 20
  | 10;

export type AvatarProps = {
  fileName?: string; // filename is the filename as stored in the avatar bucket
  localUrl?: string; // local url overrides filename if provided, it should be a local path (used in AvatarCreator)
  size?: FrameSize;
  frameShape?: FrameShape;
  hasShadow?: boolean;
  hasBorder?: boolean;
  customCss?: SerializedStyles;
  Icon?: IconType;
  onLoad?: () => void;
  isOrg?: boolean;
};

const getStyle = (size: FrameSize) => ({
  wrapper: css({
    position: "relative",
    display: "flex",
  }),
  shadow: css({
    filter:
      "drop-shadow(0px 1px 3px rgba(27, 27, 27, 0.1)) drop-shadow(0px 1px 2px rgba(27, 27, 27, 0.06))",
  }),
  clipPath: css({
    transform: `scale(${sizeProps[size].imageSize / sizeProps[56].imageSize})`,
  }),
  icon: css({
    position: "absolute",
    right: 0,
    bottom: 0,
  }),
});

export const Avatar = ({
  fileName,
  localUrl,
  size = 56,
  frameShape = "circle",
  hasShadow = true,
  hasBorder = true,
  customCss,
  Icon,
  onLoad,
  isOrg = false,
}: AvatarProps) => {
  frameShape = isOrg ? "roundedSquare" : frameShape || "circle";
  const clipId = uniqueId(`${frameShape}-${size}-clip-`);
  const dilateId = `${frameShape}-${size}-dilate`;
  const pathD = frameShapePaths[frameShape];
  const { strokeWidth, imageSize } = sizeProps[size];
  const translate = (size - imageSize) / 2;
  const iconSize = Math.round(size * 0.35);
  const style = getStyle(size);

  return (
    <div css={style.wrapper}>
      <svg
        role="img"
        aria-label="avatar"
        css={[hasShadow ? style.shadow : undefined, customCss]}
        height={size}
        width={size}
      >
        <defs>
          <clipPath id={clipId}>
            <path d={pathD} css={style.clipPath} />
          </clipPath>
          <filter id={dilateId}>
            <feMorphology
              operator="dilate"
              in="SourceGraphic"
              radius={strokeWidth}
            />
          </filter>
        </defs>

        <g transform={`translate(${translate},${translate})`}>
          {hasBorder && (
            <g
              style={{
                filter: `url("#${dilateId}")`,
              }}
            >
              <rect
                style={{
                  clipPath: `url(#${clipId})`,
                }}
                x={0}
                y={0}
                height={`${size}px`}
                width={`${size}px`}
                fill={white}
              ></rect>
            </g>
          )}
          <image
            data-testid="avatar-image"
            style={{
              clipPath: `url(#${clipId})`,
            }}
            xlinkHref={
              localUrl ||
              getAvatarPath(
                fileName || (isOrg ? placeholderOrgLogo : placeholderAvatar),
                imageSize
              )
            }
            height={`${imageSize}px`}
            width={`${imageSize}px`}
            onLoad={onLoad}
          />
        </g>
      </svg>
      {Icon && (
        <div css={style.icon}>
          <Icon width={iconSize} height={iconSize} />
        </div>
      )}
    </div>
  );
};
