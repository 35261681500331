import { useEffect } from "react";

import { useRouter } from "next/router";

import { AuthGatedProvider } from "contexts/AuthGatedContext";
import { useAuth } from "contexts/authContext";

import { getSignInUrl } from "utils/urls";

type Props = {
  children: React.ReactNode;
  redirectToSignIn?: boolean;
  redirectTo?: string;
  onSuccessfulAuthRedirect?: string;
};

export const Authenticated = ({
  children,
  redirectToSignIn = false,
  redirectTo,
  onSuccessfulAuthRedirect,
}: Props) => {
  const { user, isLoaded } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (user || !isLoaded) return;

    if (redirectTo && router.isReady) router.push(redirectTo);

    if (redirectToSignIn && router.isReady) {
      router.push(
        getSignInUrl({ currentPath: router.asPath, onSuccessfulAuthRedirect })
      );
    }
  }, [
    user,
    isLoaded,
    onSuccessfulAuthRedirect,
    redirectTo,
    redirectToSignIn,
    router,
  ]);

  return user ? <AuthGatedProvider>{children}</AuthGatedProvider> : null;
};
